import { motion } from 'framer-motion';

const ListAnimations: React.FC<{
  children: React.ReactElement<any>;
  index?: number;
}> = (props) => {
  return (
    <motion.div
      initial={{ x: -200, scale: 0, opacity: 0 }}
      animate={{ x: 0, scale: 1, opacity: 1 }}
      exit={{ x: 150, scale: 0, opacity: 0 }}
      transition={{ duration: 0.4, delay: 0.1 * (props.index || 0 + 1) }}
    >
      {props.children}
    </motion.div>
  );
};

export { ListAnimations };
