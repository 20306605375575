import { CircularProgress } from '@mui/material';

const Loading: React.FC = () => {
  return (
    <CircularProgress
      style={{ top: '50%', left: '50%', position: 'absolute' }}
    />
  );
};

export default Loading;
