function DynMap() {
  return (
    <div
      style={{
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <iframe
        style={{ margin: 'Auto' }}
        title='DynMap'
        src='http://185.218.124.9:8123/'
        width='1800'
        height='800'
      ></iframe>
    </div>
  );
}

export default DynMap;
