import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {Link as RouterLink} from 'react-router-dom';
import changeLanguage from './LanguageSelector';
import NotImplemented from './NotImplemented';
import {LoginHandler} from './Login';

const pages = [
    ['Home', '/'],
    ['Apps', '/Apps'],
    // ['Projects', '/Projects'],
    // ['About', '/About'],
];

const settings: [string, any][] = [
    ['Profile', NotImplemented],
    ['Account', NotImplemented],
    ['Dashboard', NotImplemented],
    ['Toggle Language', changeLanguage],
];

const NavBar: React.FC = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: any) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position='static'>
            <Container maxWidth='xl'>
                <Toolbar disableGutters>
                    <Typography
                        variant='h6'
                        noWrap
                        component='div'
                        sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}
                    >
                        {/* LOGO: TODO  */}
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size='large'
                            aria-label='account of current user'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={handleOpenNavMenu}
                            color='inherit'
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page[0]} onClick={handleCloseNavMenu}>
                                    <Typography
                                        style={{color: 'inherit', textDecoration: 'inherit'}}
                                        variant='inherit'
                                        component={RouterLink}
                                        to={page[1]}
                                    >
                                        {page[0]}
                                    </Typography>
                                    {/* TODO Fix Color on light mode (nav buttons) */}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant='h6'
                        noWrap
                        component='div'
                        sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}
                    >
                        {/* LOGO: TODO */}
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page) => (
                            <Typography
                                key={page[0]}
                                variant='button'
                                component={RouterLink}
                                to={page[1]}
                                onClick={handleCloseNavMenu}
                                style={{
                                    marginRight: '2rem',
                                    color: 'inherit',
                                    textDecoration: 'inherit',
                                }}
                            >
                                {page[0]}
                            </Typography>
                        ))}
                    </Box>

                    {/*<Box sx={{ flexGrow: 0 }}>*/}
                    {/*  <Tooltip title='Open settings'>*/}
                    {/*    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>*/}
                    {/*      <Avatar alt='Unknown' src='/static/images/avatar/1.jpg' />*/}
                    {/*    </IconButton>*/}
                    {/*  </Tooltip>*/}
                    {/*  <Menu*/}
                    {/*    sx={{ mt: '45px' }}*/}
                    {/*    id='menu-appbar'*/}
                    {/*    anchorEl={anchorElUser}*/}
                    {/*    anchorOrigin={{*/}
                    {/*      vertical: 'top',*/}
                    {/*      horizontal: 'right',*/}
                    {/*    }}*/}
                    {/*    keepMounted*/}
                    {/*    transformOrigin={{*/}
                    {/*      vertical: 'top',*/}
                    {/*      horizontal: 'right',*/}
                    {/*    }}*/}
                    {/*    open={Boolean(anchorElUser)}*/}
                    {/*    onClose={handleCloseUserMenu}*/}
                    {/*  >*/}
                    {/*    {settings.map((setting) => (*/}
                    {/*      <MenuItem key={setting[0]} onClick={setting[1]}>*/}
                    {/*        <Typography textAlign='center'>{setting[0]}</Typography>*/}
                    {/*      </MenuItem>*/}
                    {/*    ))}*/}
                    {/*    <LoginHandler />*/}
                    {/*  </Menu>*/}
                    {/*</Box>*/}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavBar;
