import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';

function Home() {
  const { t } = useTranslation();
  const pageMotion = {
    initial: { opacity: 0, x: -100 },
    animate: { opacity: 1, x: 0, transition: { duration: 2 } },
    exit: { opacity: 0, x: 100, transition: { duration: 2 } },
  };
  return (
    <div
      style={{
        height: 'auto',
        width: 'auto',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          zIndex: 50,
          margin: 'auto',
          marginTop: '10%',
        }}
      >
        <Typography variant='subtitle1' component='div'>
          {t('Home.welcome')}
        </Typography>
        <Typography variant='h2' component='div'>
          BitBuffered
        </Typography>
        <Typography variant='subtitle1' component='div'>
          {t('Home.explanation_1')}
        </Typography>
      </div>
    </div>
  );
}

export default Home;
