import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

class Application {
    constructor(readonly appName: String, readonly description: String) {
    }
}

const Apps: React.FC = () => {
    const applications = [
        new Application("Toads", "Keep track of your scores using this virtual scoreboard app!")
    ]

    return (
        <div
            style={{
                height: 'auto',
                width: 'auto',
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    zIndex: 50,
                    margin: 'auto',
                    marginTop: '10%',
                }}
            >
                {applications.map((application) => (
                    <Box display="inline-block" maxWidth="24em">
                        <Card>
                            <CardHeader
                                title={application.appName}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    {application.description}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    href="/Toads"
                                    size="medium">
                                    Learn More
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                ))}
            </div>
        </div>
    )
}

export default Apps;