import { useTranslation } from 'react-i18next';
import React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

import { CardActionArea, Paper, Button } from '@mui/material';

type Row = string[];
type Grid = Row[];

const TicTacToe = () => {
  const { t } = useTranslation();

  const [isXsTurn, setIsXsTurn] = useState(true);

  const [gameOver, setGameOver] = useState(false);

  const [winner, setWinner] = useState('');

  const emptyGrid = () => {
    let x = [];
    x.push(Array(3).fill(undefined));
    x.push(Array(3).fill(undefined));
    x.push(Array(3).fill(undefined));
    return x;
  };

  const [grid, setGrid] = useState(emptyGrid());

  const parentClicked = (i: number) => {
    let newGrid = grid.slice();
    let r = Math.floor(i / 3);
    let c = i % 3;
    if (grid[r][c] !== undefined || gameOver) {
      return;
    }
    if (isXsTurn) {
      newGrid[r][c] = 'X';
    } else {
      newGrid[r][c] = 'O';
    }
    setIsXsTurn(!isXsTurn);
    setGrid(newGrid);
  };

  useEffect(() => {
    const checkForWinner = () => {
      const checkRows = (g: Grid) => {
        let w = undefined;
        g.forEach((row: Row) => {
          if (row[0] === row[1] && row[0] === row[2] && row[0] !== undefined) {
            w = row[0];
          }
        });
        return w;
      };
      const checkDiagonals = (g: Grid) => {
        if (g[0][0] === g[1][1] && g[0][0] === g[2][2]) {
          return g[0][0];
        } else if (g[0][2] === g[1][1] && g[0][2] === g[2][0]) {
          return g[0][2];
        }
      };
      let transposedGrid = grid.slice();
      transposedGrid = transposedGrid[0].map((_, colIndex) =>
        transposedGrid.map((row) => row[colIndex])
      );
      const checkDraw = (g: Grid) => {
        var emptySquares = 0;
        g.forEach((row: Row) => {
          row.forEach((square) => {
            if (square === undefined) {
              emptySquares++;
            }
          });
        });
        return emptySquares === 0 ? 'Draw' : undefined;
      };

      return (
        checkRows(grid) ||
        checkRows(transposedGrid) ||
        checkDiagonals(grid) ||
        checkDraw(grid) ||
        null
      );
    };
    let winner = checkForWinner();
    if (winner) {
      setGameOver(true);
      setWinner(winner);
    }
  }, [grid]);

  return (
    <div
      style={{
        textAlign: 'center',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '3rem',
      }}
    >
      <Typography variant='h4'>{t('projects.tictactoe.title')}</Typography>
      <RowDiv>
        <Square
          value={grid[0][0]}
          index={0}
          handleClick={parentClicked}
          key={0}
        ></Square>
        <Square
          value={grid[0][1]}
          index={1}
          handleClick={parentClicked}
          key={1}
        ></Square>
        <Square
          value={grid[0][2]}
          index={2}
          handleClick={parentClicked}
          key={2}
        ></Square>
      </RowDiv>
      <RowDiv>
        <Square
          value={grid[1][0]}
          index={3}
          handleClick={parentClicked}
          key={3}
        ></Square>
        <Square
          value={grid[1][1]}
          index={4}
          handleClick={parentClicked}
          key={4}
        ></Square>
        <Square
          value={grid[1][2]}
          index={5}
          handleClick={parentClicked}
          key={5}
        ></Square>
      </RowDiv>
      <RowDiv>
        <Square
          value={grid[2][0]}
          index={6}
          handleClick={parentClicked}
          key={6}
        ></Square>
        <Square
          value={grid[2][1]}
          index={7}
          handleClick={parentClicked}
          key={7}
        ></Square>
        <Square
          value={grid[2][2]}
          index={8}
          handleClick={parentClicked}
          key={8}
        ></Square>
      </RowDiv>
      <GameController
        gameOver={gameOver}
        resetGame={() => [
          setGrid(emptyGrid()),
          setGameOver(false),
          setIsXsTurn(true),
        ]}
        winner={winner}
      />
    </div>
  );
};

const GameController: React.FC<{
  gameOver: boolean;
  resetGame: Function;
  winner: string;
}> = (props) => {
  const { t } = useTranslation();

  if (!props.gameOver) {
    return <></>;
  }
  const endMessage =
    props.winner !== 'Draw'
      ? props.winner + t('projects.tictactoe.win_msg')
      : t('projects.tictactoe.draw');
  return (
    <>
      <Typography variant='subtitle1'>{endMessage}</Typography>
      <Button
        className='standard-button border-2'
        onClick={() => props.resetGame()}
      >
        {t('projects.tictactoe.reset')}
      </Button>
    </>
  );
};

const RowDiv = (props: any) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: '5rem',
        margin: '1rem',
      }}
    >
      {props.children}
    </div>
  );
};

const Square: React.FC<{
  index: number;
  value: string;
  handleClick: Function;
}> = (props) => {
  return (
    <Paper
      component={CardActionArea}
      elevation={1 + props.index * 2}
      onClick={() => props.handleClick(props.index)}
      style={{
        height: '5rem',
        width: '5rem',
        textAlign: 'center',
        margin: '0.5rem',
        lineHeight: '5.5rem',
      }}
    >
      <p style={{ fontSize: '3rem', margin: 'auto', top: '50%', left: '50%' }}>
        {props.value}
      </p>
    </Paper>
  );
};

export default TicTacToe;
