import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';

const NotFound = () => {
  const { t, i18n } = useTranslation();
  return (
    <div
      style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}
    >
      <div style={{ top: '35%', position: 'absolute' }}>
        <h2>
          <h2 className='Page-Title'>{i18n.t('errors.404')}</h2>
        </h2>
        <Button component={RouterLink} to='/'>
          {t('errors.return')}
        </Button>
        <p> :( </p>
      </div>
    </div>
  );
};

export default NotFound;
