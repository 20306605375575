// import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { Paper, TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

type TodoList = string[];

const Todos: React.FC = () => {
  const localStorage = window.localStorage;
  var localStorageTodoList: any = localStorage.getItem('TodoList');
  console.log(localStorageTodoList);
  if (localStorageTodoList) {
    localStorageTodoList = JSON.parse(localStorageTodoList);
  }
  const [todoList, setTodoList] = useState<string[]>(
    localStorageTodoList || []
  );

  const { t } = useTranslation();

  const ResetList: Function = () => {
    setTodoList([]);
  };

  const AddItem: Function = (newItem: string) => {
    if (newItem === undefined || newItem === '') {
      console.log('Not adding item (Text Box Empty)');
      return;
    }
    let newList = [...todoList];
    console.log(newList);
    newList.unshift(newItem);
    console.log(newList);
    setTodoList(newList);
  };

  const RemoveItem: Function = (itemIdx: number) => {
    todoList.splice(itemIdx, 1);
    setTodoList([...todoList]);
    console.log(todoList);
  };

  useEffect(() => {
    localStorage.setItem('TodoList', JSON.stringify(todoList));
  }, [todoList, setTodoList, localStorage]);

  return (
    <div
      style={{
        marginTop: '3rem',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <div
        style={{
          marginTop: '1rem',
          order: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <InputItem handleAddItem={AddItem} />
        <Button onClick={() => ResetList()}>
          {t('projects.todolist.reset')}
        </Button>
      </div>
      <div
        style={{
          order: 2,
          minWidth: '300px',
          maxWidth: '800px',
          position: 'relative',
        }}
      >
        <TodoItems todoList={todoList} handleRemove={RemoveItem} />
      </div>
    </div>
  );
};

const InputItem: React.FC<{ handleAddItem: Function }> = (props) => {
  const { t } = useTranslation();

  return (
    <TextField
      onKeyPress={(ev: any) => {
        if (ev.key === 'Enter') {
          props.handleAddItem(ev.target.value);
          ev.target.value = '';
          ev.preventDefault();
        }
      }}
      id='input-item-box'
      label={t('projects.todolist.new_item')}
      variant='outlined'
      autoComplete='off'
    />
  );
};

const TodoItems: React.FC<{ todoList: TodoList; handleRemove: Function }> = (
  props
) => {
  return (
    <div>
      {props.todoList.map((item, itemIdx) => (
        <Paper
          style={{ width: '100%', margin: '1rem', padding: '1rem' }}
          key={itemIdx}
        >
          {item}{' '}
          <DeleteIcon
            onClick={() => props.handleRemove(itemIdx)}
            style={{ cursor: 'pointer', right: 0, position: 'absolute' }}
          />
        </Paper>
      ))}
    </div>
  );
};

export default Todos;
