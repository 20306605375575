import React from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n';

import { motion, MotionConfigContext } from 'framer-motion';
import { Typography } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import Socials from './Socials';
import './About.css';
import { ListAnimations } from '../components/animations';

const About: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '100%',
        flexDirection: 'column',
        textAlign: 'center',
        marginTop: '3rem',
        minHeight: '88vh',
        position: 'relative',
      }}
    >
      <div style={{ margin: 'auto', maxWidth: '800px', minWidth: '300px' }}>
        <Typography variant='h3'>{t('about.title')}</Typography>
        <Typography variant='body1'>{t('about.content')}</Typography>
        <Typography variant='body1'>{t('about.university')}</Typography>
        {/* <div className='spacer waves flip' /> */}
        {/* <MyTechnologies /> */}
        <br />
        <Socials />
        <MyTimeline />
      </div>
      <motion.div
        initial={{ y: 300, zIndex: -10, opacity: 0 }}
        animate={{ y: 1, zIndex: -10, opacity: 1 }}
        exit={{ y: 300, zIndex: -10, opacity: 0 }}
        transition={{ type: 'linear', duration: '1' }}
      >
        <div
          style={{ position: 'absolute', zIndex: -10, bottom: 0 }}
          className='spacer waves-bottom'
        />
      </motion.div>
    </div>
  );
};
type tech = { name: string; description: string };
const MyTechnologies: React.FC = () => {
  const techList: tech[] = [
    {
      name: 'Python',
      description:
        'Extensive experience working with many different popular Python librares, e.g., Pandas, NumPy, PyTorch',
    },
    {
      name: '',
      description:
        'Extensive experience working with many different popular Python librares, e.g., Pandas, NumPy, PyTorch',
    },
    {
      name: 'Python',
      description:
        'Extensive experience working with many different popular Python librares, e.g., Pandas, NumPy, PyTorch',
    },
    {
      name: 'Python',
      description:
        'Extensive experience working with many different popular Python librares, e.g., Pandas, NumPy, PyTorch',
    },
    {
      name: 'Python',
      description:
        'Extensive experience working with many different popular Python librares, e.g., Pandas, NumPy, PyTorch',
    },
    {
      name: 'Python',
      description:
        'Extensive experience working with many different popular Python librares, e.g., Pandas, NumPy, PyTorch',
    },
  ];

  return (
    <>
      <Typography variant='h5'>Technologies</Typography>
      <ul>
        {techList.map((t) => {
          return <MyTechnologyItem name={t.name} description={t.description} />;
        })}
      </ul>
    </>
  );
};

const MyTechnologyItem: React.FC<tech> = (props) => {
  return (
    <li>
      <Typography sx={{ textAlign: 'left' }} variant='h6'>
        {props.name}
      </Typography>
      <Typography variant='body1'>{props.description}</Typography>
    </li>
  );
};

const MyTimelineItem: React.FC<{
  index: number;
  date: string;
  description: string;
}> = (props) => {
  // const floatDirection = props.index % 2 === 0 ? 'left' : 'right';
  return (
    <ListAnimations index={props.index}>
      <TimelineItem style={{ marginLeft: -230 }}>
        <TimelineOppositeContent color='text.secondary'>
          {props.date}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography style={{ maxWidth: '300px' }}>
            {props.description}
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </ListAnimations>
  );
};

const MyTimeline: React.FC = () => {
  return (
    <Timeline>
      <Typography variant='h5'>Experience</Typography>
      <MyTimelineItem
        date='Apr. 2021'
        description='Began studying BSc Computer Science at the Open University'
        index={0}
      />
      <MyTimelineItem
        date='Nov. 2019'
        description='Promoted to Mobile Applications Tester at Metricell Ltd.'
        index={1}
      />
      <MyTimelineItem
        date='Feb. 2019'
        description='Joined Metricell Ltd. (Service Desk Agent)'
        index={2}
      />
      <MyTimelineItem
        date='2018'
        description='Graduated from the College of Richard Collyer'
        index={3}
      />
    </Timeline>
  );
};

export default About;
