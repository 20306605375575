import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

var domain: string;
var clientId: string;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  domain = 'dev-qbkcu3nl.us.auth0.com';
  clientId = 'dm5J1wjBuqx6VkqVLQFKgE9EvfJuEex4';
} else {
  domain = 'bitbuffered.eu.auth0.com';
  clientId = 'RoCZMhdeALCVQVRyW0KBcBiKw9eA4Qi6';
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
