import Link from '@mui/material/Link';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import { Typography } from '@mui/material';

const Socials = () => {
  return (
    <div>
      <Typography variant='h5'>Contact</Typography>
      <span>
        <GitHubIcon />
        <Link
          style={{ marginLeft: '0.5rem' }}
          href='https://github.com/ElliotSknr'
        >
          ElliotSknr
        </Link>
      </span>
      <br />
      <span>
        <EmailIcon />
        <Link
          style={{ marginLeft: '0.5rem' }}
          href='mailto:Elliot@elliotskinner.dev'
        >
          Elliot@elliotskinner.dev
        </Link>
      </span>
    </div>
  );
};

export default Socials;
