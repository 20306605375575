import React from 'react';
import {Container, Typography, Box, Button, Grid, Paper, Link} from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import ToadsContactForm from './ToadsContactForm';

const Toads = () => {
    return (
        <Container maxWidth="lg">
            <Box my={4} textAlign="center">
                <Typography variant="h2" gutterBottom>
                    Toads: Virtual Scoreboard
                </Typography>
                <Typography variant="h5" gutterBottom>
                    The ultimate companion for enthusiasts of the pub game Toads.
                </Typography>
                <Box my={3}>
                    <Typography variant="h6" gutterBottom>
                        Available on iOS and Android
                    </Typography>
                    <Button
                        disabled
                        variant="contained"
                        startIcon={<AppleIcon/>}
                        style={{marginRight: '10px'/*, backgroundColor: '#000000', color: '#ffffff'*/}}
                    >
                        <Link href="#" color="inherit" underline="none">
                            Download on the App Store
                        </Link>
                    </Button>
                    <Button
                        disabled
                        variant="contained"
                        startIcon={<AndroidIcon/>}
                        //style={{backgroundColor: '#000000', color: '#ffffff'}
                    >
                        <Link href="#" color="inherit" underline="none">
                            Get it on Google Play
                        </Link>
                    </Button>
                </Box>
            </Box>

            <ToadsContactForm/>
        </Container>
    );
};

export default Toads;
