import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Paper } from '@mui/material';
import Stack from '@mui/material/Stack';

import { ListAnimations } from '../../components/animations';

const Projects = () => {
  const { t } = useTranslation();
  type project = { title: string; description: string; path: string };
  const projectsList: project[] = [
    {
      title: t('projects.weather.title'),
      description: 'Weather App (React/ TypeScript)',
      path: './Weather',
    },
    {
      title: t('projects.todolist.title'),
      description: 'Simple To-do list (React/ TypeScript)',
      path: './Todos',
    },
    {
      title: t('projects.tictactoe.title'),
      description: 'A lightweight tic-tac-toe game (React/ TypeScript)',
      path: './TicTacToe',
    },
    {
      title: t('projects.cards.title'),
      description: 'Deck of cards shuffler/ sorter (React/ TypeScript)',
      path: './Cards',
    },
    {
      title: t('projects.chess.title'),
      description: 'Playable in-browser chess game (Unity)',
      path: 'https://bitbuffered-legacy.herokuapp.com/Unity/Chess/',
    },
  ];
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Stack style={{ display: 'flex', marginTop: '3rem' }} spacing={2}>
        {projectsList.map((prj, idx) => {
          return (
            <ListAnimations index={idx}>
              <ProjectCard
                title={prj.title}
                description={prj.description}
                path={prj.path}
              />
            </ListAnimations>
          );
        })}
      </Stack>
    </div>
  );
};

const InnerCard: React.FC<{ title: string; description: string }> = (props) => {
  return (
    <CardActionArea>
      {/* <CardMedia
        component="img"
        height="140"
        image="/static/images/cards/contemplative-reptile.jpg"
        alt="green iguana"
      /> */}
      <CardContent>
        <Typography gutterBottom variant='h5' component='div'>
          {props.title}
        </Typography>
        <Typography variant='body2' color='text.secondary'>
          {props.description}
        </Typography>
      </CardContent>
    </CardActionArea>
  );
};

const ProjectCard: React.FC<{
  title: string;
  description: string;
  path: string;
  externalLink?: boolean;
}> = (props) => {
  if (props.externalLink) {
    return (
      <a href={props.path} style={{ textDecoration: 'none' }}>
        <Card sx={{ maxWidth: 400, minWidth: 200 }}>
          <InnerCard title={props.title} description={props.description} />
        </Card>
      </a>
    );
  }
  return (
    <Card
      component={RouterLink}
      style={{ textDecoration: 'none' }}
      to={props.path}
      sx={{ maxWidth: 400, minWidth: 200 }}
    >
      <InnerCard title={props.title} description={props.description} />
    </Card>
  );
};

export default Projects;
