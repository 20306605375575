import React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { Typography } from '@mui/material';

const generateSortedDeck = () => {
  let x: string[] = [];
  let suits = ['spades', 'clubs', 'hearts', 'diamonds'];
  suits.forEach(function (suit, column) {
    for (let i = 1; i <= 13; i++) {
      let c = { suit: suit, value: i };
      let id = c.suit + ':' + c.value;
      x.push(id);
    }
  });
  return x;
};

const createDeck = () => {
  let createdDeck: { suit: string; value: number }[] = [];
  let suits = ['spades', 'clubs', 'hearts', 'diamonds'];
  suits.forEach(function (suit, column) {
    for (let i = 1; i <= 13; i++) {
      createdDeck.push({ suit: suit, value: i });
    }
  });
  return createdDeck;
};

const Cards: React.FC = () => {
  const [cardList, setCardList] = useState(generateSortedDeck());
  const [deck] = useState(createDeck());

  const sortDeck = () => {
    setCardList(generateSortedDeck());
  };

  const cardIdentifier = (card: any) => {
    return card.suit + ':' + card.value;
  };

  const handleReshuffle = () => {
    function shuffleArray(array: any[]) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
    let newCardsData = shuffleArray(cardList);
    setCardList([...newCardsData]);
  };

  const cardPlacementValue = (card: string) => {
    let x = 0;

    let suit = card.split(':')[0];
    let value = card.split(':')[1];

    switch (suit) {
      case 'diamonds':
        x += 3;
        break;
      case 'hearts':
        x += 2;
        break;
      case 'clubs':
        x += 1;
        break;
      case 'spades':
        x += 0;
        break;
      default:
        console.log('Error comparing');
        break;
    }
    let r = +(x * 13) + +value;
    return r;
  };

  const isCardGreaterThan = (inspectedCard: string, comparisonCard: string) => {
    if (
      cardPlacementValue(inspectedCard) > cardPlacementValue(comparisonCard)
    ) {
      return true;
    }
    return false;
  };

  const cardsSorted = (cl: any) => {
    for (var i = 0; i < cl.length - 1; i++) {
      if (isCardGreaterThan(cl[i], cl[i + 1])) {
        return false;
      }
    }
    return true;
  };

  const handleBubbleSort = () => {
    const delayState = (cl: any, i: number) => {
      setTimeout(() => {
        setCardList(cl);
      }, 50 * i);
    };

    let cl = cardList;
    let cll = [];
    do {
      for (let i = 0; i < cl.length - 1; i++) {
        if (isCardGreaterThan(cl[i], cl[i + 1])) {
          let temp = cl[i + 1];
          cl[i + 1] = cl[i];
          cl[i] = temp;
        }
        cll.push([...cl]);
      }
    } while (!cardsSorted(cl));
    for (let i = 0; i < cll.length - 1; i++) {
      delayState(cll[i], i);
    }
  };

  return (
    <div
      style={{
        marginTop: '3rem',
        height: '80vh',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div style={{ order: 1, width: '80%', position: 'relative' }}>
        <div>
          <Card
            value={deck[0].value}
            suit={deck[0].suit}
            pos={cardList.indexOf(cardIdentifier(deck[0]))}
          />
          <Card
            value={deck[1].value}
            suit={deck[1].suit}
            pos={cardList.indexOf(cardIdentifier(deck[1]))}
          />
          <Card
            value={deck[2].value}
            suit={deck[2].suit}
            pos={cardList.indexOf(cardIdentifier(deck[2]))}
          />
          <Card
            value={deck[3].value}
            suit={deck[3].suit}
            pos={cardList.indexOf(cardIdentifier(deck[3]))}
          />
          <Card
            value={deck[4].value}
            suit={deck[4].suit}
            pos={cardList.indexOf(cardIdentifier(deck[4]))}
          />
          <Card
            value={deck[5].value}
            suit={deck[5].suit}
            pos={cardList.indexOf(cardIdentifier(deck[5]))}
          />
          <Card
            value={deck[6].value}
            suit={deck[6].suit}
            pos={cardList.indexOf(cardIdentifier(deck[6]))}
          />
          <Card
            value={deck[7].value}
            suit={deck[7].suit}
            pos={cardList.indexOf(cardIdentifier(deck[7]))}
          />
          <Card
            value={deck[8].value}
            suit={deck[8].suit}
            pos={cardList.indexOf(cardIdentifier(deck[8]))}
          />
          <Card
            value={deck[9].value}
            suit={deck[9].suit}
            pos={cardList.indexOf(cardIdentifier(deck[9]))}
          />
          <Card
            value={deck[10].value}
            suit={deck[10].suit}
            pos={cardList.indexOf(cardIdentifier(deck[10]))}
          />
          <Card
            value={deck[11].value}
            suit={deck[11].suit}
            pos={cardList.indexOf(cardIdentifier(deck[11]))}
          />
          <Card
            value={deck[12].value}
            suit={deck[12].suit}
            pos={cardList.indexOf(cardIdentifier(deck[12]))}
          />
          <Card
            value={deck[13].value}
            suit={deck[13].suit}
            pos={cardList.indexOf(cardIdentifier(deck[13]))}
          />
          <Card
            value={deck[14].value}
            suit={deck[14].suit}
            pos={cardList.indexOf(cardIdentifier(deck[14]))}
          />
          <Card
            value={deck[15].value}
            suit={deck[15].suit}
            pos={cardList.indexOf(cardIdentifier(deck[15]))}
          />
          <Card
            value={deck[16].value}
            suit={deck[16].suit}
            pos={cardList.indexOf(cardIdentifier(deck[16]))}
          />
          <Card
            value={deck[17].value}
            suit={deck[17].suit}
            pos={cardList.indexOf(cardIdentifier(deck[17]))}
          />
          <Card
            value={deck[18].value}
            suit={deck[18].suit}
            pos={cardList.indexOf(cardIdentifier(deck[18]))}
          />
          <Card
            value={deck[19].value}
            suit={deck[19].suit}
            pos={cardList.indexOf(cardIdentifier(deck[19]))}
          />
          <Card
            value={deck[20].value}
            suit={deck[20].suit}
            pos={cardList.indexOf(cardIdentifier(deck[20]))}
          />
          <Card
            value={deck[21].value}
            suit={deck[21].suit}
            pos={cardList.indexOf(cardIdentifier(deck[21]))}
          />
          <Card
            value={deck[22].value}
            suit={deck[22].suit}
            pos={cardList.indexOf(cardIdentifier(deck[22]))}
          />
          <Card
            value={deck[23].value}
            suit={deck[23].suit}
            pos={cardList.indexOf(cardIdentifier(deck[23]))}
          />
          <Card
            value={deck[24].value}
            suit={deck[24].suit}
            pos={cardList.indexOf(cardIdentifier(deck[24]))}
          />
          <Card
            value={deck[25].value}
            suit={deck[25].suit}
            pos={cardList.indexOf(cardIdentifier(deck[25]))}
          />
          <Card
            value={deck[26].value}
            suit={deck[26].suit}
            pos={cardList.indexOf(cardIdentifier(deck[26]))}
          />
          <Card
            value={deck[27].value}
            suit={deck[27].suit}
            pos={cardList.indexOf(cardIdentifier(deck[27]))}
          />
          <Card
            value={deck[28].value}
            suit={deck[28].suit}
            pos={cardList.indexOf(cardIdentifier(deck[28]))}
          />
          <Card
            value={deck[29].value}
            suit={deck[29].suit}
            pos={cardList.indexOf(cardIdentifier(deck[29]))}
          />
          <Card
            value={deck[30].value}
            suit={deck[30].suit}
            pos={cardList.indexOf(cardIdentifier(deck[30]))}
          />
          <Card
            value={deck[31].value}
            suit={deck[31].suit}
            pos={cardList.indexOf(cardIdentifier(deck[31]))}
          />
          <Card
            value={deck[32].value}
            suit={deck[32].suit}
            pos={cardList.indexOf(cardIdentifier(deck[32]))}
          />
          <Card
            value={deck[33].value}
            suit={deck[33].suit}
            pos={cardList.indexOf(cardIdentifier(deck[33]))}
          />
          <Card
            value={deck[34].value}
            suit={deck[34].suit}
            pos={cardList.indexOf(cardIdentifier(deck[34]))}
          />
          <Card
            value={deck[35].value}
            suit={deck[35].suit}
            pos={cardList.indexOf(cardIdentifier(deck[35]))}
          />
          <Card
            value={deck[36].value}
            suit={deck[36].suit}
            pos={cardList.indexOf(cardIdentifier(deck[36]))}
          />
          <Card
            value={deck[37].value}
            suit={deck[37].suit}
            pos={cardList.indexOf(cardIdentifier(deck[37]))}
          />
          <Card
            value={deck[38].value}
            suit={deck[38].suit}
            pos={cardList.indexOf(cardIdentifier(deck[38]))}
          />
          <Card
            value={deck[39].value}
            suit={deck[39].suit}
            pos={cardList.indexOf(cardIdentifier(deck[39]))}
          />
          <Card
            value={deck[40].value}
            suit={deck[40].suit}
            pos={cardList.indexOf(cardIdentifier(deck[40]))}
          />
          <Card
            value={deck[41].value}
            suit={deck[41].suit}
            pos={cardList.indexOf(cardIdentifier(deck[41]))}
          />
          <Card
            value={deck[42].value}
            suit={deck[42].suit}
            pos={cardList.indexOf(cardIdentifier(deck[42]))}
          />
          <Card
            value={deck[43].value}
            suit={deck[43].suit}
            pos={cardList.indexOf(cardIdentifier(deck[43]))}
          />
          <Card
            value={deck[44].value}
            suit={deck[44].suit}
            pos={cardList.indexOf(cardIdentifier(deck[44]))}
          />
          <Card
            value={deck[45].value}
            suit={deck[45].suit}
            pos={cardList.indexOf(cardIdentifier(deck[45]))}
          />
          <Card
            value={deck[46].value}
            suit={deck[46].suit}
            pos={cardList.indexOf(cardIdentifier(deck[46]))}
          />
          <Card
            value={deck[47].value}
            suit={deck[47].suit}
            pos={cardList.indexOf(cardIdentifier(deck[47]))}
          />
          <Card
            value={deck[48].value}
            suit={deck[48].suit}
            pos={cardList.indexOf(cardIdentifier(deck[48]))}
          />
          <Card
            value={deck[49].value}
            suit={deck[49].suit}
            pos={cardList.indexOf(cardIdentifier(deck[49]))}
          />
          <Card
            value={deck[50].value}
            suit={deck[50].suit}
            pos={cardList.indexOf(cardIdentifier(deck[50]))}
          />
          <Card
            value={deck[51].value}
            suit={deck[51].suit}
            pos={cardList.indexOf(cardIdentifier(deck[51]))}
          />
        </div>
      </div>
      <div style={{ order: 2, width: '20%' }}>
        <Selector
          handleReshuffle={handleReshuffle}
          handleSort={sortDeck}
          handleBubbleSort={handleBubbleSort}
        />
      </div>
    </div>
  );
};

const Selector: React.FC<{
  handleBubbleSort: Function;
  handleReshuffle: Function;
  handleSort: Function;
}> = (props) => {
  return (
    <div style={{ textAlign: 'center', justifyContent: 'center' }}>
      <Typography variant='h5'>Card Sorter</Typography>
      <br />
      <Button onClick={() => props.handleBubbleSort()}>Bubble Sort</Button>
      <br />
      <Button onClick={() => props.handleReshuffle()}>Shuffle Cards</Button>
      <br />
      <Button onClick={() => props.handleSort()}>Reset Cards</Button>
    </div>
  );
};

const Card: React.FC<{ value: number; suit: string; pos: number }> = (
  props: any
) => {
  const maxRows = 4;
  const maxCols = 13;
  let value: string;
  switch (props.value) {
    case 1:
      value = 'Ace';
      break;
    case 11:
      value = 'Jack';
      break;
    case 12:
      value = 'Queen';
      break;
    case 13:
      value = 'King';
      break;
    default:
      value = props.value;
  }

  let suit;
  let cardColour;
  switch (props.suit) {
    case 'diamonds':
      suit = '♦';
      cardColour = 'red';
      break;
    case 'hearts':
      suit = '♥';
      cardColour = 'red';
      break;
    case 'clubs':
      suit = '♣';
      cardColour = '';
      break;
    case 'spades':
      suit = '♠';
      cardColour = '';
      break;
    default:
      suit = 'error';
      break;
  }
  let identifier = value + suit;
  return (
    <Paper
      style={{
        color: cardColour,
        left: ((props.pos % 13) / maxCols) * 90 + 5 + '%',
        top: (Math.floor(props.pos / 13) / maxRows) * 100 + '%',
        height: '8rem',
        width: '5rem',
        opacity: '100%',
        transitionProperty: 'all',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDuration: '500ms',
        position: 'absolute',
        borderRadius: '0.5rem',
      }}
      id={identifier}
    >
      <Typography
        variant='subtitle2'
        style={{
          textAlign: 'left',
          fontSize: '1rem',
          marginLeft: '0.2rem',
          marginTop: '0.2rem',
        }}
      >
        {value} {suit}
      </Typography>
    </Paper>
  );
};

export default Cards;
