import * as React from 'react';
import {Suspense} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './i18n';
import {
    Route as RouterRoute,
    Routes as RouterRoutes,
    useLocation,
} from 'react-router-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {NavBar, Loading} from './components';
import {Home, About, CV, Profile, NotFound, DynMap} from './Pages';

import {Projects, TicTacToe, Cards, Todos, Weather} from './Pages/Projects';
import Apps from './Pages/Apps/Apps';
import ProstPrivacy from './Pages/Prost/ProstPrivacy';
import Toads from './Pages/Toads/Toads';
import ToadsPrivacy from './Pages/Toads/ToadsPrivacy';
import {AnimatePresence, motion} from 'framer-motion';

const App: React.FC = () => {
//    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const prefersDarkMode = false;

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                    primary: {
                        main: '#000000',
                    },
                    secondary: {
                        main: '#b02429',
                    },
                },
            }),
        [prefersDarkMode]
    );
    const location = useLocation();

    type page = { path: string; element: React.ReactElement<any> };
    const pageRoutes: page[] = [
        {path: '*', element: <NotFound/>},
        {path: '/', element: <Home/>},
        {path: '/Apps', element: <Apps/>},
        {path: '/Projects', element: <Projects/>},
        {path: '/Projects/TicTacToe', element: <TicTacToe/>},
        {path: '/Projects/Todos', element: <Todos/>},
        {path: '/Projects/Weather', element: <Weather/>},
        {path: '/Projects/Cards', element: <Cards/>},
        {path: '/About', element: <About/>},
        {path: '/cv', element: <CV/>},
        {path: '/Profile', element: <Profile/>},
        {path: '/Minecraft/Dynmap', element: <DynMap/>},
        {path: '/Prost/Privacy', element: <ProstPrivacy/>},
        {path: '/Toads', element: <Toads/>},
        {path: '/Toads/Privacy', element: <ToadsPrivacy/>},
    ];
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Suspense fallback={<Loading/>}>
                <NavBar/>
                <AnimatePresence exitBeforeEnter>
                    <RouterRoutes location={location} key={location.pathname}>
                        {pageRoutes.map((page) => {
                            return (
                                <RouterRoute
                                    path={page.path}
                                    element={<AnimateWrapper element={page.element}/>}
                                />
                            );
                        })}
                    </RouterRoutes>
                </AnimatePresence>
            </Suspense>
        </ThemeProvider>
    );
};

const AnimateWrapper: React.FC<{ element: React.ReactElement<any> }> = (
    props
) => {
    const pageMotion = {
        initial: {opacity: 0},
        animate: {opacity: 1, transition: {duration: 0.25}},
        exit: {opacity: 0, transition: {duration: 0.25}},
    };

    return (
        <motion.div
            initial='initial'
            animate='animate'
            exit='exit'
            variants={pageMotion}
        >
            {props.element}
        </motion.div>
    );
};

export default App;
