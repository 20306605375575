import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from '../components';

const Profile = () => {
  const { user, isAuthenticated } = useAuth0();

  if (!isAuthenticated || user === undefined) {
    return <Loading />;
  }

  return (
    <div>
      <p>
        Username: <i style={{ opacity: 100, fontSize: '.8em' }}>{user.name}</i>
      </p>
    </div>
  );
};

export default Profile;
