import React, {useState} from 'react';
import {Container, Typography, Box, TextField, Button} from '@mui/material';

const ToadsContactForm = () => {
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');

    const handleSendEmail = () => {
        const mailtoLink = `mailto:toadscontact@bitbuffered.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(description)}`;
        window.location.href = mailtoLink;
    };

    return (
        <Container maxWidth="sm">
            <Box my={4} textAlign="center">
                <Typography variant="h4" gutterBottom>
                    Contact Developer
                </Typography>

                <TextField
                    label="Subject"
                    fullWidth
                    margin="normal"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />

                <TextField
                    label="Description"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendEmail}
                    style={{marginTop: '20px'}}
                >
                    Send
                </Button>
            </Box>
        </Container>
    );
};

export default ToadsContactForm;