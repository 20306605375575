import React from 'react';
import '../i18n';
import { Row, Col } from 'react-grid-system';

import Socials from './Socials';

const CV: React.FC = (props: any) => {
  var passcode = '9738441504930';
  var search = props.history.location.search;
  var search_list = search.split('?');
  var auth = false;
  search_list.shift();
  search_list.forEach((element: any) => {
    var elx = element.split('=');
    if (elx[0] === 'passcode') {
      if (elx[1] === passcode) {
        auth = true;
      }
    }
  });
  if (auth) {
    return (
      <div>
        <h2>Elliot Skinner</h2>
        <ProgrammingLanguages />
        <br />
        <JobExperience />
        <br />
        <Socials />
      </div>
    );
  } else {
    return <NoAuth />;
  }
};

const JobExperience: React.FC = () => {
  return (
    <div>
      <h3>Experience</h3>
      <i>Metricell Ltd. | Horsham</i>
      <br />
      <i>Mobile App. Software Tester Nov 2019 – Present</i>
      <ul>
        <li>
          Performing Software QA/ Testing for both iOS and Android applications;
          Unit Tests, Debugging
        </li>
        <li>Introduced python/django-driven automated regression testing</li>
        <li>
          Heavy usage of tools such as Git, Android Studio to debug, modify and
          build applications for testing
        </li>
      </ul>
      <Row>
        <Col sm={3}>Metricell</Col>
        <Col sm={4}>Mobile Application Tester</Col>
        <Col sm={2}>Nov 2019</Col>
        <Col sm={2}>Present</Col>
      </Row>
      <Row>
        <Col sm={3}>Metricell</Col>
        <Col sm={4}>Service Desk Agent</Col>
        <Col sm={2}>Feb 2019</Col>
        <Col sm={2}>Nov 2019</Col>
      </Row>
    </div>
  );
};

const ProgrammingLanguages: React.FC = () => {
  return (
    <>
      <div>
        <h4> Python </h4>
        <p>
          {' '}
          Working with popular frameworks, including Django, Pandas, NumPy, etc.{' '}
        </p>
      </div>
      <div>
        <h4> Javasript </h4>
        <p> React (This is a react site), AJAX, jQuery </p>
      </div>
      <div>
        <h4> SQL </h4>
        <p> further expositions </p>
      </div>
      <div>
        <h4> Java </h4>
        <p> further expositions </p>
      </div>
      <div>
        <h4> C# </h4>
        <p> further expositions </p>
      </div>
      <div>
        <h4> Apache/ Spark </h4>
        <p> further expositions </p>
      </div>
    </>
  );
};

const NoAuth: React.FC = () => {
  return (
    <div>
      <p>You are not authorised to view this page</p>
      <form>
        <label className='text-xs'>Passcode</label>
        <input name='passcode' />
        <br />
        <input type='submit' value='Submit request' />
      </form>
      <p>For enquires please contact</p>
      <Socials />
    </div>
  );
};

export default CV;
