import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Typography } from '@mui/material';
import { Loading } from '../../components';

const apikey = 'HfcYx3VlguGC3VnLSwY3oelGjWv89SWP';

const Weather: React.FC = () => {
  const [locationDetails, setLocationDetails] = useState<any>({});
  const [weatherDetails, setWeatherDetails] = useState<any>({});

  const getDetailsFromLatLong: Function = (
    latitude: string,
    longitude: string
  ) => {
    console.log('Getting Location');
    axios
      .get(
        'https://dataservice.accuweather.com/locations/v1/cities/geoposition/search?apikey=' +
          apikey +
          '&q=' +
          latitude +
          ',' +
          longitude
      )
      .then((res) => {
        setLocationDetails(res.data);
      });
  };

  useEffect(() => {
    if (
      'geolocation' in navigator &&
      Object.keys(locationDetails).length === 0
    ) {
      navigator.geolocation.getCurrentPosition(function (pos) {
        getDetailsFromLatLong(pos.coords.latitude, pos.coords.longitude);
      });
    } else {
      // console.log('Already have location or navigation not possible.');
    }
  }, [locationDetails]);

  useEffect(() => {
    if (
      Object.keys(locationDetails).length > 0 &&
      Object.keys(weatherDetails).length === 0
    ) {
      console.log('Getting weather details');
      axios
        .get(
          'https://dataservice.accuweather.com/currentconditions/v1/' +
            locationDetails['Key'] +
            '?apikey=' +
            apikey
        )
        .then((res) => setWeatherDetails(res.data[0]));
    }
  }, [locationDetails, setWeatherDetails, weatherDetails]);

  return (
    <div
      style={{
        textAlign: 'center',
        marginTop: '3rem',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <WeatherCard weatherDetails={weatherDetails} />
    </div>
  );
};

const WeatherCard: React.FC<{ weatherDetails: any }> = (props) => {
  if (Object.keys(props.weatherDetails).length === 0) {
    return <Loading />;
  }
  console.log(props.weatherDetails);
  return (
    <Card
      style={{
        padding: '1rem',
        marginLeft: '2rem',
        marginRight: '2rem',
        maxWidth: '800px',
        minWidth: '300px',
      }}
    >
      <Typography variant='h5'>{props.weatherDetails.WeatherText}</Typography>
      <Typography variant='body1'>
        Temperature: {props.weatherDetails.Temperature.Metric.Value} celsius
      </Typography>
    </Card>
  );
};

export default Weather;
