import ls from 'local-storage';
import i18n from '../i18n';

const changeLanguage = () => {
  const setLang = (lang) => {
    ls.set('language', lang);
    i18n.changeLanguage(lang);
  };

  var lang = ls.get('language') || navigator.language.slice(0, 2) || 'en';
  // console.log(lang);
  if (lang === 'en') {
    setLang('de');
    return;
  } else if (lang === 'de') {
    setLang('en');
    return;
  } else if ('language' in navigator) {
    if (navigator.language.includes('DE')) {
      setLang('de');
      return;
    }
  } else {
    setLang('en');
    return;
  }
};

export default changeLanguage;
